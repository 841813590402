<script>
export default {
  form: {
    default_vat: {
      label: 'TVA par défaut de vos formations (en %)',
      type: 'number',
      column: 'is-12',
      inputAttrs: {
        min: 0,
        max: 100,
        step: 0.01,
      },
    },
  },
  data() {
    return {
      isLoading: false,
      store: {
        default_vat: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    Object.keys(this.store)
      .forEach((k) => (this.store[k] = this.authStore[k]));
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/updateStore', { ...this.store })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        TVA
      </h2>
      <p>
        Indiquez ici le taux de TVA que vous appliquez par défaut pour vos formations.
        Si vous appliquez des taux différents selon les formations, vous pourrez définir des taux spécifiques sur chaque formation.<br>
        Vous n'êtes pas soumis à la TVA ? Indiquez 0.
      </p>
    </div>

    <div class="column">
      <div class="box">
        <h3 class="title is-7 mb-3">
          Vous ne savez pas quel taux de TVA choisir ?
        </h3>
        <p>
          Suivez notre tutoriel sur le sujet en
          <a
            class="has-text-weight-bold"
            href="https://www.youtube.com/watch?v=kgYpMaRFwKI"
            target="_blank"
            rel="noopener noreferrer">
            cliquant ici
          </a>
        </p>
      </div>

      <form novalidate class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
            <BaseField v-model="store[k]" :field="field" />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
