<script>
import { mapGetters } from 'vuex';
import { storeForms } from '@app/mixins/forms';
import APIPayment from '@app/services/API/Payment';

export default {
  mixins: [storeForms],
  data() {
    return {
      store: {
        feature_options: {
          paypal: {
            disabled: false,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
  },
  methods: {
    getPayPalUrl() {
      const loader = this.$buefy.loading.open();
      APIPayment.getPayPalConnectData()
        .then(({ data: { links = [] } = {} }) => {
          const url = links.find((v) => v.rel === 'action_url')?.href;

          if (!url) {
            this.$buefy.dialog.alert({
              type: 'is-danger',
              message: `
                Une erreur est survenue lors de la connexion, veuillez recharger la page et recommencer.
              `,
            });
            return;
          }

          window.location.href = url;
        })
        .finally(() => loader.close());
    },
    onDeactivate() {
      if (this.store.feature_options.paypal.disabled) {
        this.$buefy.dialog.confirm({
          type: 'is-danger',
          title: 'Êtes-vous sûr.e ?',
          message: `
            En désactivant les paiements avec PayPal,
            cette option de paiement ne sera plus proposée
            à vos futurs apprenants.
          `,
          focusOn: 'cancel',
          onConfirm: () => this.handle(),
          onCancel: () => this.$nextTick(() => (
            this.store.feature_options.paypal.disabled = false
          )),
          confirmText: 'Je confirme',
        });

        return;
      }

      this.handle();
    },
  },
  created() {
    const { merchantIdInPayPal } = this.$route.query;

    if (merchantIdInPayPal) {
      const loader = this.$buefy.loading.open();
      APIPayment.paypalConnect({ merchant_id: merchantIdInPayPal })
        .then((data) => {
          this.$store.commit('auth/updateStore', data.data);
          this.$router.push({ query: {} });
        })
        .finally(() => {
          loader.close();
          this.$router.push({ query: {} });
        });
    }
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Encaissez des paiements avec PayPal
      </h2>
      <p>
        Connectez votre compte PayPal Business pour augmenter
        vos chances de vendre, en proposant un moyen de paiement
        supplémentaire.
      </p>
    </div>
    <div class="column">
      <GoProWrapper :hasSep="false">
        <div class="box content">
          <p v-if="authStore.paypal_token_at">
            <b-icon
              class="valign-middle mr-4"
              size="is-large"
              icon="cc-paypal"
              pack="fab"
            />
            Votre compte PayPal est connecté depuis le
            <strong>{{ authStore.paypal_token_at | momentFromUTC | moment('DD/MM/YY') }}</strong>.
          </p>
          <template v-else>
            <p>
              Connectez votre compte PayPal Business dès maintenant.
              Si vous n'en avez pas, créez-en un gratuitement.
            </p>
            <p class="has-text-centered">
              <b-button
                size="is-larger"
                icon-left="paypal"
                icon-pack="fab"
                outlined
                @click="getPayPalUrl"
              >
                Connexion PayPal
              </b-button>
            </p>
          </template>

          <div
            v-if="authStore.paypal_token_at"
            class="mt-10 is-relative"
          >
            <b-switch
              v-model="store.feature_options.paypal.disabled"
              @input="dataIsValid(isLoading) && onDeactivate()">
              Désactiver les paiements avec PayPal
            </b-switch>

            <b-loading v-model="isLoading" :is-full-page="false" />
          </div>
        </div>
      </GoProWrapper>
    </div>
  </div>
</template>
