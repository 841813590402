<script>
import APIPayment from '@app/services/API/Payment';
import StoreVatForm from '@app/components/StoreVatForm.vue';
import StorePaypalSettingsForm from '@app/components/StorePaypalSettingsForm.vue';

export default {
  components: {
    StoreVatForm,
    StorePaypalSettingsForm,
  },
  head: {
    title: 'Paiements - Paramètres',
  },
  data() {
    const redirectUrl = encodeURIComponent(`${this.$env.appURL}/parametres/paiements`);
    return {
      url: `
        https://connect.stripe.com/oauth/authorize?client_id=${this.$env.stripeConnectID}&amp;scope=read_write&amp;response_type=code&amp;redirect_uri=${redirectUrl}
      `,
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    const { code } = this.$route.query;

    if (code) {
      const loader = this.$buefy.loading.open();
      APIPayment.connect({ code })
        .then((data) => {
          this.$store.commit('auth/updateStore', data.data);
          this.$router.push({ query: {} });
        })
        .finally(() => {
          loader.close();
          this.$router.push({ query: {} });
        });
    }
  },
};
</script>

<template>
  <div>
    <div class="columns">
      <div class="column is-4 content pr-10 is-small">
        <h2 class="is-size-5">
          Encaissez des paiements par carte bancaire
        </h2>
        <p>
          Nous passons par <strong>Stripe</strong>, un service sécurisé de paiement en ligne pour garantir l'environnement le plus sécurisant pour vous et vos apprenants.
        </p>
      </div>
      <div class="column">
        <div class="box content">
          <p v-if="authStore.stripe_token_at">
            <b-icon class="valign-middle mr-4" size="is-large" icon="cc-stripe" pack="fab" />
            Votre compte Stripe est connecté depuis le
            <strong>{{ authStore.stripe_token_at | momentFromUTC | moment('DD/MM/YY') }}</strong>.
          </p>
          <template v-else>
            <p>
              <strong>La connexion à Stripe vous permet d'encaisser les paiements de vos apprenants</strong>.
            </p>
            <p>
              Nous ne stockons aucune carte bancaire dans notre système,
              les paiements sont traités par <strong>Stripe</strong> et
              versés sur votre compte bancaire à la fréquence souhaitée.
            </p>

            <p>
              Inscrivez-vous gratuitement ou connectez-vous à votre compte Stripe dès maintenant.
            </p>

            <p class="has-text-centered">
              <b-button
                tag="a"
                size="is-larger"
                :href="url"
                icon-left="stripe-s"
                icon-pack="fab"
                target="_blank"
                rel="noreferrer noopener"
                outlined=""
              >
                Connexion Stripe
              </b-button>
            </p>
          </template>
        </div>
      </div>
    </div>

    <StorePaypalSettingsForm />
    <StoreVatForm />
  </div>
</template>
